import React, { useState } from "react";

import { useLogin, useTranslate } from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  theme,
} from "antd";

import "./styles.css";
import { UseLoginReturnType } from "@refinedev/core/dist/hooks/auth/useLogin";
import useScreenSize from "utils/useScreenSize";
import LanguageSwitcher from "components/LanguageSwitcher";

const { Text, Title } = Typography;

export interface ISignInMagicForm {
  email: string;
}

export interface ISignInPassForm {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const t = useTranslate();
  const [signInForm] = Form.useForm<ISignInMagicForm>();
  const [signInPassForm] = Form.useForm<ISignInPassForm>();
  const { token } = theme.useToken();
  const { width } = useScreenSize();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(window.location.search).get("tab") || "magic"
  );

  const { mutate: login, isLoading }: UseLoginReturnType<ISignInMagicForm> =
    useLogin<ISignInMagicForm>();

  const CardTitle = (
    <div style={{ textAlign: "center" }}>
      <Title level={3} className="title">
        {t("Welcome back")}
      </Title>
      <Text>{t("Sign in to your account using")}</Text>
    </div>
  );

  const tabList = [
    {
      key: "magic",
      tab: t("Magic Link"),
    },
    {
      key: "pass",
      tab: t("Email/Password"),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    magic: (
      <Form<ISignInMagicForm>
        layout="vertical"
        form={signInForm}
        onFinish={(values: ISignInMagicForm) => login(values)}
        requiredMark={false}
      >
        <Form.Item
          label={t("Email")}
          name="email"
          rules={[{ required: true }]}
          style={{ marginBottom: "12px" }}
        >
          <Input
            type="email"
            placeholder="you@example.com"
            size="large"
            autoComplete="email"
          />
        </Form.Item>
        <div style={{ marginBottom: "12px" }}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox
              style={{
                fontSize: "12px",
              }}
            >
              {t("Remember me")}
            </Checkbox>
          </Form.Item>
        </div>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          block
          loading={isLoading}
        >
          {t("Authorize")}
        </Button>
      </Form>
    ),
    pass: (
      <Form<ISignInPassForm>
        layout="vertical"
        form={signInPassForm}
        onFinish={(values: ISignInPassForm) => login(values)}
        requiredMark={false}
      >
        <Form.Item
          label={t("Email")}
          name="email"
          rules={[{ required: true }]}
          style={{ marginBottom: "12px" }}
        >
          <Input
            type="email"
            placeholder="you@example.com"
            size="large"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item
          label={t("Password")}
          name="password"
          rules={[{ required: true }]}
          style={{ marginBottom: "12px" }}
        >
          <Input
            type="password"
            placeholder="●●●●●●●●"
            size="large"
            autoComplete="current-password"
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox
              style={{
                fontSize: "12px",
              }}
            >
              {t("Remember me")}
            </Checkbox>
          </Form.Item>
          <a className="login-form-forgot" href="/forgot-password">
            {t("Forgot password")}
          </a>
        </div>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          block
          loading={isLoading}
        >
          {t("Sign In")}
        </Button>
        {/* <div
          style={{ display: "flex", justifyContent: "center", marginTop: 15 }}
        >
          <Text>
            {t("Don't have an account?")}{" "}
            <a href="/register">{t("Sign Up Now")}</a>
          </Text>
        </div> */}
      </Form>
    ),
  };

  return (
    <Layout className="layout" style={{ background: token.colorPrimary }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
              tabList={tabList}
              activeTabKey={activeTab}
              onTabChange={(key) => setActiveTab(key)}
              tabProps={{ centered: true }}
            >
              {contentList[activeTab]}
            </Card>
          </div>
        </Col>
      </Row>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          left: width / 2 - 100,
          width: 200,
        }}
      >
        <LanguageSwitcher />
      </div>
    </Layout>
  );
};
